<template>
  <div class="centerList" :class="isShowRight ? 'isshow' : ''">
    <div class="centerList-title">
      <el-button class="copyrightIncome-item" @click="isShowUpload" type="text"
        >上传版权文字作品</el-button
      >
      已上传授权作品列表
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="序号" :width="!isShowRight ? '120px' : '120px'">
        <template slot-scope="{ row: { status, isParse }, $index: index }">
          <div
            :class="[
              'states',
              isParse == 1
                ? status === 1
                  ? 'green'
                  : 'orange'
                : isParse == 0
                ? 'gray'
                : isParse == 2
                ? 'eparsed'
                : isParse == 3
                ? 'hasparse'
                : '',
            ]"
          >
            {{
              isParse == 1
                ? status === 1
                  ? "已上架"
                  : "已下架"
                : isParse == 0
                ? "解析中"
                : isParse == 2
                ? "解析失败"
                : isParse == 3
                ? "内容已存在"
                : ""
            }}
          </div>
          <div>{{ index + 1 }}</div>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="文件名"
        :width="!isShowRight ? '315px' : ''"
      >
        <template slot-scope="scope">
          <div class="fileName">
            {{ scope.row.filename }} <i class="modifyIcon"></i>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="作者" :width="!isShowRight ? '100px' : '150px'">
        <template slot-scope="scope">
          <div class="author">
            {{ scope.row.author }}
            <!-- <i @click="handleAuthor(scope.row)" class="modifyIcon"></i> -->
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="创作时间"
        :width="!isShowRight ? '150px' : '180px'"
      >
        <template slot-scope="scope">
          <div class="publishTime">
            {{
              scope.row.publishTime && scope.row.publishTime.substring(0, 10)
            }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="授权价格"
        :width="!isShowRight ? '140px' : '170px'"
      >
        <template slot-scope="scope">
          <div class="price">
            {{ scope.row.price }}
            {{
              scope.row.filetype == 1 && scope.row.setPrice == 0 ? "字符" : "元"
            }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="收益" :width="!isShowRight ? '140px' : '200px'">
        <template slot-scope="scope">
          <div>
            {{ scope.row.cashIncome }}元 / {{ scope.row.characterIncome }} 字符
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="98px">
        <template slot-scope="{ row, row: { status }, $index: i }">
          <div class="operation">
            <span class="more" @click.stop="selectMenu(i)">
              <div :class="['submenu', subIndex === i ? 'submenu-active' : '']">
                <span @click="detailsClick(row, i)">查看详情</span>
                <template v-if="row.isParse == 3">
                  <span v-if="spliceMd5(row.md5)" @click="goren(i)"
                    >版权认领</span
                  >
                  <span v-else @click="error_flag = true">版权纠错</span>
                </template>
                <span @click="handlePublishTime(row)">修改时间</span>
                <span @click="handlePublishprice(row)">修改价格</span>
                <span v-if="row.isParse == 1" @click="handleShelves(row)"
                  >{{ status === 1 ? "下架" : "上架" }}作品</span
                >
              </div>
            </span>
            <span class="delete" @click="handleDelete(row.id)"><i></i></span>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="no-document" v-show="tableData.length == 0">
      <img src="../../assets/images/no-document.png" alt="" />
      暂无文档
    </div>

    <el-pagination
      v-if="tableData.length != 0"
      background
      layout="prev, pager, next"
      prev-text="上一页"
      next-text="下一页"
      :page-size="limit"
      :current-page="page"
      @prev-click="lastPageChange"
      @next-click="nextPageChange"
      @current-change="currentChange"
      :total="total"
    >
    </el-pagination>

    <!-- 修改创造时间弹窗 -->
    <el-dialog
      title="修改创作时间"
      :modal="false"
      width="30%"
      :visible.sync="dialogVisible"
    >
      <div class="text">
        创作时间是版权作最核心要素，我们是非常谨慎，敬请体谅。
      </div>
      <el-form :model="dialogRuleForm" label-position="left" label-width="80px">
        <el-form-item label="现时间"> {{ currentdate }} </el-form-item>
        <el-form-item label="拟修改">
          <el-date-picker
            v-model="dialogRuleForm.dialogDate"
            style="width: 100%"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请输入修改时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="佐证材料">
          <el-input
            v-model="dialogRuleForm.proofImgUrl"
            placeholder="可以提供佐证网址，供我们工作人员查看核准。"
          ></el-input>
        </el-form-item>
        <el-form-item label="佐证图片">
          <input
            id="upload"
            style="display: none"
            type="file"
            name="frontFile"
            ref="backFile"
            @change="doRealUpload"
          />
          <div v-show="!proofMaterial" class="upimg" @click="uploadFile">
            点击上传相关佐证图片
          </div>
          <div v-show="proofMaterial" class="upimg" @click="uploadFile">
            {{ file }}
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="amendPublishTime(dialogRuleForm)"
          >提交</el-button
        >
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 修改作者 -->
    <el-dialog title="修改作者" :visible.sync="authorVisible">
      <div class="select">
        <div class="text">作者</div>
        <el-select
          v-model="value1"
          style="width: 100%"
          placeholder="请选择作者"
        >
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="addauthor_box">
        <div class="placeholder"></div>
        <div class="addauthor" @click="inpshow = true">+ 新增作者</div>
      </div>

      <div class="addauthor_box" v-show="inpshow">
        <div class="placeholder"></div>
        <div class="addauthor">
          <el-input
            @change="authorChange"
            class="authorinp"
            v-model="input"
            placeholder="请输入作者"
          ></el-input>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="authorVisible = false"
          >确定</el-button
        >
        <el-button @click="authorVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 修改授权价格弹窗 -->
    <el-dialog
      class="priceVisible"
      title="修改授权价格"
      width="25%"
      :visible.sync="priceVisible"
    >
      <div class="cash">
        授权类型：
        <div class="text">
          依据《中华人民共和国著作权法》授予本人作品的“信息网络传播权”
        </div>
      </div>
      <div class="shape">授权价格:</div>

      <div class="checked">
        <div class="checked_box">
          <el-checkbox v-model="priceChecked" @change="handleChecked(1)">
            <span class="checkbox_content">
              系统默认，付费标准：文档按全文字符数、图片按1
              元/张、音频按3元/个、视频按5元/个授权收费。
            </span>
          </el-checkbox>
        </div>

        <el-checkbox v-model="priceInputChecked" @change="handleChecked(2)">
          请输入价格(元)
        </el-checkbox>

        <el-input
          type="number"
          v-show="priceInputChecked"
          v-model="priceInput"
          @focus="handleChecked(2)"
          placeholder="请输入价格"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="modifyPrice">确 定</el-button>
        <el-button @click="priceVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 提交成功 -->
    <div v-if="success_flag" class="success">
      <img :src="require('assets/images/chenggong.png')" />
      <span
        >提交成功，我们将在1-3个工作日内处理完成，出现无法核实等复杂情况时，我们将及时和您沟通。感谢您的支持！</span
      >
    </div>

    <!-- 详细信息 -->
    <el-dialog title="详细信息" :visible.sync="infoFilesPopup" class="addFil">
      <div class="box">
        <span class="boxSpan">文件名：</span>&nbsp;&nbsp;&nbsp;
        <span> {{ valInfo.filename }}</span>

        <img
          @click="downloadFil"
          class="download"
          :src="require('assets/images/info/download.png')"
          alt=""
        />
      </div>
      <div class="box">
        <span class="boxSpan">序号：</span>&nbsp;&nbsp;&nbsp;
        <span> {{ valInfo.index }}</span>
      </div>
      <div class="box">
        <span class="boxSpan">作者：</span>&nbsp;&nbsp;&nbsp;
        <span>
          {{ valInfo.author }}
        </span>
      </div>
      <div class="box">
        <span class="boxSpan">收益：</span>&nbsp;&nbsp;&nbsp;
        <span>
          {{ valInfo.cashIncome }}元 / {{ valInfo.characterIncome }} 字符
        </span>
      </div>
      <div class="box">
        <span class="boxSpan">创作时间：</span>&nbsp;&nbsp;&nbsp;
        <span>
          {{ valInfo.publishTime }}
        </span>
      </div>
      <div class="box">
        <span class="boxSpan">授权价格：</span>&nbsp;&nbsp;&nbsp;
        <span>
          {{ valInfo.price }}
          {{ valInfo.filetype == 1 && valInfo.setPrice == 0 ? "字符" : "元" }}
        </span>
      </div>
    </el-dialog>

    <!-- 版权真实性承诺 -->
    <el-dialog
      title="版权真实性承诺"
      :visible.sync="copyrightVisible"
      class="copyrightVisible"
    >
      <div class="text">
        本人承诺所上传作品均为本人真实创作的版权作品，愿意为此承担法律责任。
      </div>

      <div class="signature_box">
        <div class="signature_text">承诺人签名</div>
        <div
          v-show="!authorSignatureImg"
          class="signature"
          @click="(signaTureShow = true), (authorSignatureImg = '')"
        >
          点击签名
        </div>
      </div>
      <img
        @click="(signaTureShow = true), (authorSignatureImg = '')"
        v-show="authorSignatureImg"
        :src="authorSignatureImg"
        class="authorSignatureImg"
      />
      <div class="commitment_box">
        <div class="commitmentText">承诺时间</div>
        <div class="commitmentDate">{{ commitmentCurrentdate }}</div>
        <div class="commitmentTime">{{ currenttime }}</div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleAddCommitment">确定</el-button>
        <el-button @click="copyrightVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 签名画布组件 -->
    <el-dialog title="签名" :visible.sync="signaTureShow" width="50%">
      <Signature
        class="SignatureCanvas"
        :width="500"
        :height="300"
        @handleSubmit="handleSignature"
      />
    </el-dialog>

    <!--版权Word文档上传附件弹出框 -->
    <el-dialog
      v-dialogDrag
      title="版权Word文档上传"
      :visible.sync="uploadVisible"
      class="upload"
      :modal="false"
      @close="handleClose"
    >
      <div class="content-text">
        <!-- <div class="title">
          请使用统一规范的文件名，文件名由“作者、主标题、文献类型、时间”四部分组成。各部分之间用圆点“.”隔开。
        </div> -->

        <div class="demand_box">
          <div class="demand_text">阅读需求</div>
          <div class="demand_inp" @click="uploadNoticeVisible = true">
            版权文字作品上传须知
          </div>
        </div>
        <div class="choose_data_box">
          <div class="choose_text"><span>*</span> 选择数据库</div>
          <!-- <div class="choose_radio">
            <div
              class="choose_item"
              v-for="(item, i) in chooseDataList"
              :key="i"
            >
              <input
                :id="item.id"
                type="radio"
                name="chooseDat"
                v-model="parentId"
                :value="item.id"
              />
              <label :for="item.id">{{ item.conName }}</label>
            </div>
          </div> -->
           <input
                type="text"
                v-model="parentId"
              />
        </div>

        <div class="choose_price_box">
          <div class="choose_text"><span>*</span> 选择价格</div>
          <div class="choose_radio">
            <div
              class="choose_item"
              v-for="(item, i) in choosePriceList"
              :key="i"
            >
              <input
                :id="item.id"
                type="radio"
                name="choosePrice"
                v-model="choosePriceId"
                :value="item.id"
              />
              <label :for="item.id">{{ item.name }}</label>
            </div>
            <el-input
              @input="handleInput(demandPriceInput)"
              v-show="choosePriceId == 2"
              type="Number"
              v-model="demandPriceInput"
              placeholder="【价格上限为30元】"
            ></el-input>
          </div>
        </div>
      </div>
      <UploadBigFile
        class="uploadSlot"
        :parentId="Number(parentId)"
        :columnType="columnType"
        :fileType="fileType"
        :money="choosePriceId == 2 ? demandPriceInput : '0'"
        @fileAdded="fileAdded"
        @onFileSuccess="onFileSuccess"
        ref="uploader"
      >
      </UploadBigFile>

      <span slot="footer" class="dialog-footer">
        <el-button @click="sureUpload" v-if="fileList.length"
          >确定上传</el-button
        >
        <el-button @click="clearUpload" v-if="fileList.length">清空</el-button>
        <el-button @click="uploadVisible = false">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 版权文字作品上传须知弹窗 -->
    <el-dialog
      v-dialogDrag
      title="版权文字作品上传须知"
      :visible.sync="uploadNoticeVisible"
      class="uploadNotice"
      :modal="false"
    >
      <div class="content-text">
        <div class="title">1.上传版权文字作品的三大好处。</div>
        <div class="text_box">
          通过版权作品自主设定价格，获得现金、字符包等收益。
        </div>
        <div class="sequence_txt">
          <span>(1)</span>
          保护个人知识产权。在写手智能科技官网上传作品，可以免费获得时间戳认证，成为应对版权侵权的铁证。写手智能科技在全网检索中，如发现侵权行为，会及时告知版权人，保护个人知识产权。
        </div>
        <div class="sequence_txt">
          <span>(2)</span>
          持续获得反抄袭的字符收入。文字作品的最早原创者，在所有溯源中支付的字符数，涉及到篇、段、句的层面，都将直接获得重复字段的字符数。
        </div>

        <div class="text_box">
          例如：微信公众号知名博主王中锤，耗费三月有余创作了5000字的《时间就是第一生产力》，文笔优美、金句频频，随后被各种花式洗稿、拆分得七零八落，投诉、举报乃至诉讼均难以奏效。王中锤将《时间就是第一生产力》，上传至写手智能科技公司版权作品数据库。随后，某媒体机构在审核其拟刊发的3000字的《时间生产力》一文时，向写手智能科技公司支付了3000个字符，溯源发现其中2800字符均出自于王中锤的《时间就是第一生产力》，则王中锤可以自写手智能科技公司转获得2800字符，该字符可以直接提现。目前，字符提现价格为0.25元/千字。
        </div>

        <div class="sequence_txt">
          <span>(3)</span>
          持续获得购买用户支付的费用。用户需要阅读、下载全文，需要向版权作者支付购买费用。
        </div>

        <div class="title">2.上传版权文字作品的必备条件</div>
        <div class="sequence_txt">
          <span>(1)</span>
          拥有文字作品真实有效的版权；
        </div>
        <div class="sequence_txt">
          <span>(2)</span>
          文字作品载体为doc/docx/wps格式。
        </div>

        <div class="text_box">第一要素，作者为实名认证用户名，不可修改.</div>

        <div class="text_box">
          第二要素， 第二要素，文件名，选择要上传的文件后点击文件名可修改。
        </div>
        <div class="text_box">
          第三要素，文献标识符。选择要上传的文件后点击文献类型可修改。
        </div>
        <div class="text_box">
          第四要素，来源。包括发表期刊、出版社、首次发布网站等,默认为写手智能科技数据库，选择要上传的文件后点击来源可修改。
        </div>
        <div class="text_box">
          第五要素，创作时间。首次发表时间、最后成稿时间。默认为当前日期，选择要上传的文件后点击创作日期可修改。
        </div>
      </div>
    </el-dialog>
    <!-- 认证弹窗 -->
    <div v-if="ren_flag" class="claim">
      <img
        @click="close"
        class="close"
        :src="require('assets/images/close.png')"
      />
      <div class="renzheng">
        <img :src="require('assets/images/renzheng.png')" />
        <span>认领版权需要实名认证</span>
      </div>
      <div class="xuanze">
        <div @click="gorealName" class="yes">现在认证</div>
        <div class="xian"></div>
        <div @click="close" class="no">取消</div>
      </div>
    </div>
    <!-- 认领弹窗 -->
    <div v-if="claimn_flag" class="claimn">
      <div class="claimnNav">
        <div class="title">
          <img :src="require('assets/images/renling.png')" />
          <span>待认领版权-认领方式</span>
        </div>
        <img
          @click="closecl"
          class="title_x"
          :src="require('assets/images/close.png')"
        />
      </div>
      <div class="wangzhi">
        <input
          @click="dian($event)"
          style="cursor: pointer"
          id="websit"
          type="radio"
          name="proof"
        />
        <label for="websit">网址佐证</label>
      </div>
      <div class="wangzhi">
        <input
          @click="dian($event)"
          style="cursor: pointer"
          id="photo"
          type="radio"
          name="proof"
        />
        <label for="photo">照片或资料佐证</label>
      </div>
      <span class="w-title">网址佐证</span>
      <input v-model="website" class="shuruw" placeholder="请输入网址" />
      <span class="w-title">照片或资料佐证</span>
      <input
        id="photos"
        type="file"
        @change="realUpload"
        class="shuruw"
        name="frontFile"
        ref="backFile1"
        style="opacity: 0"
      />
      <div v-if="flie == ''" class="file-tip" @click="uploadFile1">
        点击上传照片或资料佐证
      </div>
      <div
        v-if="flie != ''"
        class="file-tip"
        @click="uploadFile1"
        v-text="flie"
      ></div>
      <span class="w-title">备注</span>
      <textarea v-model="r_remark" placeholder="请输入备注" />
      <div class="botton">
        <el-button @click="submit" type="primary">提交</el-button>
        <el-button @click="closecl">取消</el-button>
      </div>
    </div>
    <!-- 纠错弹窗 -->
    <div v-if="error_flag" class="claimn">
      <div class="claimnNav">
        <div class="title">
          <img :src="require('assets/images/lanbanquan.png')" />
          <span>版权纠错</span>
        </div>
        <img
          @click="closeer"
          class="title_x"
          :src="require('assets/images/close.png')"
        />
      </div>
      <span class="w-title">著作权人有误</span>
      <input v-model="owner" class="shuruw" placeholder="请输入准确著作权人" />
      <span class="w-title">网址佐证</span>
      <input v-model="websiter" class="shuruw" placeholder="请输入网址" />
      <span class="w-title">照片或资料佐证</span>
      <input
        @change="realUpload"
        id="file"
        type="file"
        class="shuruw"
        name="frontFile"
        ref="backFile1"
        style="opacity: 0"
      />
      <div v-if="flie == ''" class="file-tip" @click="1">点击上传</div>
      <div
        v-if="flie != ''"
        class="file-tip"
        @click="uploadFile1"
        v-text="flie"
      ></div>
      <span class="w-title">备注</span>
      <textarea v-model="remark" placeholder="请输入备注" />
      <div class="botton">
        <el-button @click="submiter" type="primary">提交</el-button>
        <el-button @click="closeer">取消</el-button>
      </div>
    </div>
    <!-- 提交认领成功 -->
    <div v-if="succ_flag" class="success">
      <img src="require('assets/images/chenggong.png')" />
      <span
        >提交成功，我们将在1-3个工作日内处理完成，出现无法核实等复杂情况时，我们将及时和您沟通。感谢您的支持！</span
      >
    </div>
  </div>
</template>

<script>
import {
  getList,
  publishTime,
  updatePrice,
  updateShelves,
  commitment,
  addCommitment,
  thirdpart,
  delWorks,
} from "api/copyrightIncome";
import { apply, error } from "api/included";
import { upload } from "api/common";
import { downloadFile } from "api/copyrightService";
import Signature from "components/Signature";
import UploadBigFile from "components/disk/CopyrightIncomeUpload.vue";
export default {
  name: "centerList",
  components: {
    Signature,
    UploadBigFile,
  },
  data() {
    return {
      options: [
        {
          value: 5,
          label: "小明",
        },
      ],
      id: "", //作品id
      priceVisible: false, // 修改价格弹窗
      priceChecked: true, //系统默认价格Checked
      priceInputChecked: false, // 输入价格Checked
      priceInput: "", // 输入价格
      authorVisible: false, // 修改作者弹窗
      value1: "", // 选择作者
      inpshow: false, // 显示添加作者输入框
      input: "", // 添加作者输入框
      dialogVisible: false, //修改创造时间弹窗
      currentdate: "", // 当前日期
      //修改创造时间弹窗
      dialogRuleForm: {
        dialogDate: "", // 拟修改
        proofImgUrl: "", // 佐证材料
      },
      proofMaterial: "", // 佐证图片
      tableData: [], //表格数据
      page: 1, //页码
      limit: 10, //一页10条数据
      total: 0, //总条数
      // 作品item
      worksItem: {},
      success_flag: false,
      // 上传文件的name
      file: "",
      // 详情弹窗
      infoFilesPopup: false,
      valInfo: "",
      // 上传作品弹窗
      uploadVisible: false,
      // 版权文字作品上传须知弹窗
      uploadNoticeVisible: false,
      //版权真实性承诺弹窗
      copyrightVisible: false,
      // 作者签名图片
      authorSignatureImg: "",
      // 显示签名组件
      signaTureShow: false,
      // 承诺当期时间
      currenttime: "",
      commitmentCurrentdate: "",
      //类型  网盘传1  版权传2 存证3  溯源4
      columnType: 2,
      // 选择的语境库id
      parentId: "",
      fileType: 1,
      // 语境库
      chooseDataList: [],
      //选择价格id
      choosePriceId: 1,
      // 价格输入框
      demandPriceInput: "",
      // 价格
      choosePriceList: [
        {
          name: "系统默认 (付费标准：文档字符数授权收费)",
          id: 1,
        },
        {
          name: "自定义价格",
          id: 2,
        },
      ],
      fileList: [],
      subIndex: -1,
      //认证弹窗
      ren_flag: false,
      //认领弹窗
      claimn_flag: false,
      //纠错弹窗
      error_flag: false,
      //网址信息
      website: "",
      //资料文件名
      flie: "",
      //认领备注
      r_remark: "",
      //纠错备注
      remark: "",
      //著作权人
      owner: "",
      //纠错网址
      websiter: "",
      //网址佐证标识
      websitFlag: false,
      //资料佐证标识
      photoFlag: false,
      // 文件路径
      fileUrl: "",
      //纠错资料
      erfile: "",
      succ_flag: false,
      //行id
      rid: 0,
    };
  },
  props: ["isShowRight"],
  methods: {
    /**
     * 按下删除按钮
     * @param { Number } id 作品id
     */
    handleDelete(id) {
      this.$confirm("是否删除该作品", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await this.deleteWorks(id);
          this.getTableList();
        })
        .catch(() => {});
    },
    /**
     * 删除作品请求
     * @param { Number } id 作品id
     */
    async deleteWorks(id) {
      const form = new FormData();
      form.append("id", id);
      const res = await delWorks(form);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "删除成功",
          duration: 1000,
        });
      } else {
        this.$message({
          type: "error",
          message: res.message,
          duration: 1000,
        });
      }
    },
    /**
     * MD5 截取 -x  x为0 展示认领  不为0展示纠错
     */
    spliceMd5(md5) {
      return md5.split("-")[1] == 0;
    },
    fileAdded(files) {
      this.fileList = files;
    },
    onFileSuccess(file) {
      if (file.id == this.fileList[this.fileList.length - 1].id) {
        this.getTableList();
      }
    },
    //确定上传
    sureUpload() {
      this.fileList.forEach((f, fi) => {
        const uploader = this.$refs["uploader"];
        uploader.computeMD5(f);
      });
    },
    clearUpload() {
      this.$refs["uploader"].tableData = [];
      this.$refs["uploader"].fileIndex = 0;
      this.fileList = [];
      this.$bus.$emit("clearFileList");
    },
    // 关闭上传弹窗
    handleClose() {
      this.demandPriceInput = "";
      this.choosePriceId = 1;
      this.parentId = this.chooseDataList[0].id;
      this.getTableList();
    },
    handleInput(val) {
      if (val > 30) {
        this.$message("抱歉,最高为30元");
        this.demandPriceInput = 30;
        return;
      } else if (val < 0) {
        this.$message("抱歉,最低为0.1元");
        this.demandPriceInput = 0.1;
        return;
      }
    },
    // 获取语境库
    async getThirdpartlist() {
      const res = await thirdpart();
      // console.log(res);
      if (res.code != 200) {
        return this.$message(res.message);
      }

      this.chooseDataList = res.data;
      this.parentId = this.chooseDataList[0].id;
    },

    // 签名组件方法
    handleSignature(val) {
      this.signaTureShow = false;
      this.authorSignatureImg = val;
    },

    // 版权真实性承诺
    async handleAddCommitment() {
      if (!this.authorSignatureImg) {
        return this.$message({
          type: "error",
          message: "请签名",
        });
      }
      const data = {
        content:
          "本人承诺所上传作品均为本人真实创作的版权作品，愿意为此承担法律责任",
        autograph: this.authorSignatureImg,
        startTime: this.currentdate + " " + this.currenttime,
      };
      const res = await addCommitment(data);
      // console.log(res);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "授权成功",
        });
        this.fuSublime();
        this.copyrightVisible = false;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    // 校验授权承诺
    async checkAuthorization() {
      const res = await commitment();
      // console.log(res);
      if (res.code === 200) {
        this.uploadVisible = true;
        this.getThirdpartlist();
      } else {
        this.copyrightVisible = true;
      }
    },

    // 校验实名和登录
    async isShowUpload() {
      this.$store.commit("CHECK_USER_OPTIONS", {
        afterCheck: () => {
          this.checkAuthorization();
        },
      });
    },

    // 下载
    async downloadFil() {
      const IFRAME = document.createElement("iframe");
      IFRAME.style.display = "none";
      IFRAME.style.height = 0;
      // src 就是请求服务地址自行修改。
      // http://fafs.antiplagiarize.com/
      IFRAME.src = `https://fafs.antiplagiarize.com/${this.valInfo.url}?attname=${this.valInfo.filename}`;
      document.body.appendChild(IFRAME);
      setTimeout(() => {
        IFRAME.remove();
      }, 60 * 1000);
    },

    // 详情弹窗
    detailsClick(val, index) {
      this.infoFilesPopup = true;
      this.valInfo = val;
      this.valInfo.index = index + 1;
    },
    // 添加作者
    authorChange() {
      let author = this.input;

      this.options.push({
        value: this.options.length,
        label: author,
      });

      this.inpshow = false;
      this.input = "";
    },
    // 修改创作时间
    async amendPublishTime() {
      if (!this.dialogRuleForm.dialogDate) {
        return this.$message({
          type: "error",
          message: "请选择拟修时间",
        });
      }
      var reg =
        /[http://|ftp://|https://|www]?[^\u4e00-\u9fa5\s]*?\.[com|net|cn|me|tw|fr][^\u4e00-\u9fa5\s]*/;

      if (!reg.test(this.dialogRuleForm.proofImgUrl)) {
        return this.$message({
          type: "error",
          message: "请填写佐证材料网络地址",
        });
      }
      if (!this.proofMaterial) {
        return this.$message({
          type: "error",
          message: "请上传佐证图片",
        });
      }
      const data = {
        portfolioId: this.id,
        updateTime: this.dialogRuleForm.dialogDate,
        proofUrl: this.dialogRuleForm.proofImgUrl,
        proofMaterial: this.proofMaterial,
      };

      const res = await publishTime(data);
      // console.log(res);

      if (res.code === 200) {
        this.dialogVisible = false;
        this.dialogRuleForm.dialogDate = "";
        this.dialogRuleForm.proofImgUrl = "";
        this.proofMaterial = "";
        this.success_flag = true;
        setTimeout(() => {
          this.success_flag = false;
        }, 4500);
        this.getTableList();
      } else {
        this.$message({
          type: "error",
          message: "提交失败",
        });
      }
    },

    // 修改作品价格
    async modifyPrice() {
      if (!this.priceChecked) {
        if (/^((-\d+(\.\d+)?)|(0+(\.0+)?))$/.test(this.priceInput)) {
          return this.$message({
            message: "授权价格不能为负数",
          });
        }
        // console.log(this.priceInput > 99999999);
        if (this.priceInput > 99999999) {
          return this.$message({
            message: "超出设置的最大金额",
          });
        }
      }
      const data = {
        portfolioId: this.worksItem.id,
        setPrice: this.priceChecked ? 0 : 1,
        price: this.priceInput,
        authorizationType: "信息网络传播权",
        authorizationTerm: 1,
      };

      const res = await updatePrice(data);

      if (res.code === 200) {
        this.priceVisible = false;
        this.priceInput = "";
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.getTableList();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 触发上传事件
    uploadFile() {
      this.$refs.backFile.value = "";
      this.$refs.backFile.dispatchEvent(new MouseEvent("click"));
    },
    // 上传图片
    async doRealUpload(e) {
      let file = e.target.files[0];
      this.erfile = file ? file.name : "";
      if (!/.(gif|jpg|jpeg|png|gif|jpg|png)$/.test(file.type)) {
        return this.$message({
          type: "error",
          message: "上传文件必须是图片",
        });
      }

      let form = new FormData();
      form.append("file", file);
      const res = await upload(form);
      if (res.code === 200) {
        this.proofMaterial = res.data;
        this.getTableList();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }

      // console.log(res);
    },
    //获取列表信息
    async getTableList() {
      const data = {
        page: this.page,
        limit: this.limit,
      };
      const res = await getList(data);
      if (res.code === 200) {
        this.tableData = res.data.data;
        this.total = res.data.total;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    ziSublime() {
      this.getTableList();
    },
    //选择上下架操作
    handleShelves(row) {
      // console.log(row);
      if (row.isParse != 1) {
        return this.$message({
          type: "info",
          message: "该作品正在解析中",
          duration: 1000,
        });
      }
      this.$confirm(
        row.status == 1 ? "是否下架该作品" : "是否上架该作品",
        row.status == 1 ? "下架" : "上架",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          await this.upperOrLowerShelves(row.id, row.status);
          this.getTableList();
        })
        .catch(() => {});
    },
    /**
     * 上/下架作品请求
     * @param { Number } id 作品id
     * @param { Number } status 1-已上架 2未上架
     */
    async upperOrLowerShelves(id, status) {
      const data = {
        id,
        type: status == 1 ? 2 : 1,
      };
      const res = await updateShelves(data);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: status == 1 ? "下架成功" : "上架成功",
        });
      } else {
        return this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    //  上一页
    lastPageChange(val) {
      this.page = val;
    },
    // page改变触发
    currentChange(cur) {
      this.page = cur;
      this.getTableList();
    },
    // 下一页
    nextPageChange(val) {
      this.page = val;
    },
    // 获取当前日期
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      var hour = date.getHours();
      var minu = date.getMinutes();
      var sec = date.getSeconds();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minu >= 0 && minu <= 9) {
        minu = "0" + minu;
      }
      if (sec >= 0 && sec <= 9) {
        sec = "0" + sec;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      this.currenttime = hour + ":" + minu + ":" + sec;
      this.commitmentCurrentdate = currentdate;
      this.currentdate = currentdate;
    },

    // 修改作者弹窗
    handleAuthor(val) {
      this.authorVisible = true;

      // console.log(val);
      this.id = val.id;
    },

    //修改创造时间弹窗
    handlePublishTime(val) {
      this.dialogVisible = true;
      // console.log(val);
      this.getNowFormatDate();
      this.id = val.id;
    },

    // 修改授权价格弹窗
    handlePublishprice(val) {
      this.priceVisible = true;
      this.id = val.id;
      this.worksItem = val;
    },

    // 1 系统价格  2 输入价格
    handleChecked(val) {
      if (val == 1) {
        this.priceChecked = true;
        this.priceInputChecked = false;
      } else {
        this.priceChecked = false;
        this.priceInputChecked = true;
      }
    },
    //选择更多菜单
    selectMenu(i) {
      if (this.subIndex === i) {
        return (this.subIndex = -1);
      }
      this.subIndex = i;
    },
    clickBlankHandler() {
      this.subIndex = -1;
    },
    //认证弹窗
    goren(i) {
      //判断是否实名
      if (this.$store.state.realNameData == null) {
        this.ren_flag = true;
      } else {
        this.claimn_flag = true;
        this.rid = i;
      }
    },
    //关闭认证弹窗
    close() {
      this.ren_flag = false;
    },
    //去认证
    gorealName() {
      this.$router.push({ path: "realName" });
    },
    // 触发上传事件
    uploadFile1() {
      this.$refs.backFile.value = "";
      this.$refs.backFile.dispatchEvent(new MouseEvent("click"));
    },
    //认领上传文件
    async realUpload(e) {
      let file = e.target.files[0];
      console.log(file);
      this.flie = file ? file.name : "";
      let form = new FormData();
      form.append("file", file);
      const res = await upload(form);
      if (res.code === 200) {
        this.fileUrl = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    //关闭认领弹窗
    closecl() {
      this.claimn_flag = false;
      //清空相关信息
      this.website = "";
      this.r_remark = "";
      this.fileUrl = "";
      this.flie = "";
      this.photoFlag = false;
      this.websitFlag = false;
      document.getElementById("photos").value == "";
    },
    //选择认领方式
    dian(e) {
      if (e.target.id == "websit") {
        this.websitFlag = true;
        this.photoFlag = false;
      }
      if (e.target.id == "photo") {
        this.photoFlag = true;
        this.websitFlag = false;
      }
    },
    //关闭成功提示
    shut() {
      this.succ_flag = false;
    },
    //关闭纠错弹窗
    closeer() {
      this.error_flag = false;
      //清空相关信息
      this.owner = "";
      this.websiter = "";
      this.remark = "";
      this.flie = "";
      this.fileUrl = "";
      document.getElementById("file").value == "";
    },
    //提交认领
    async submit() {
      //是否选择认领方式
      if (!this.websitFlag && !this.photoFlag) {
        this.$message.error("请选择认领方式");
        return;
      }

      if (!this.r_remark) {
        this.$message("请输入备注");
        return;
      }
      //是否选择网址佐证
      if (this.websitFlag) {
        //是否输入网址
        if (this.website == "") {
          this.$message.error("请输入网址");
          return;
        } else {
          //网址正则验证
          var reg =
            /[http://|ftp://|https://|www]?[^\u4e00-\u9fa5\s]*?\.[com|net|cn|me|tw|fr][^\u4e00-\u9fa5\s]*/;
          if (reg.test(this.website)) {
            //参数
            let data = {
              md5: this.tableData[this.rid].md5,
              applyType: 2,
              websiteUrl: this.website,
              remark: this.r_remark,
            };
            //发送请求
            let res = await apply(data);
            //判断响应是否成功
            if (res.code == 200) {
              //关闭弹窗
              this.claimn_flag = false;
              //清空相关信息
              this.website = "";
              this.r_remark = "";
              this.websitFlag = false;
              //显示成功弹窗
              this.succ_flag = true;
              setTimeout(this.shut, 3000);
            }
          } else {
            this.$message.error("请输入正确的网址格式");
            return;
          }
        }
      }
      //是否选择资料佐证
      if (this.photoFlag) {
        //是否上传资料
        if (this.fileUrl == "") {
          this.$message.error("请上传照片或资料");
          return;
        }

        //参数
        let data = {
          md5: this.tableData[this.rid].md5,
          applyType: 3,
          fileUrl: this.fileUrl,
          remark: this.r_remark,
        };
        //发送数据
        let res = await apply(data);
        if (res.code == 200) {
          this.claimn_flag = false;
          this.succ_flag = true;
          this.r_remark = "";
          this.flie = "";
          this.fileUrl = "";
          this.photoFlag = false;
          document.getElementById("photos").value == "";
          setTimeout(this.shut, 3000);
        }
      }
    },
    //纠错提交
    async submiter() {
      //是否输入著作权人
      if (this.owner == "") {
        this.$message.error("请输入准确著作权人");
        return;
      }
      //如果输入网址
      if (this.websiter != "") {
        //网址正则验证
        var reg =
          /[http://|ftp://|https://|www]?[^\u4e00-\u9fa5\s]*?\.[com|net|cn|me|tw|fr][^\u4e00-\u9fa5\s]*/;

        if (!reg.test(this.websiter)) {
          this.$message.error("请输入正确的网址格式");
          return;
        }
      }
      //参数
      let data = {
        md5: this.tableData[this.rid].md5,
        currentUserId: this.tableData[this.rid].originalId,
        currentAuthor: this.owner,
        websiteUrl: this.websiter,
        fileUrl: this.fileUrl,
        remark: this.remark,
      };
      //发送数据
      let res = await error(data);
      //是否响应成功
      if (res.code == 200) {
        this.error_flag = false;
        this.succ_flag = true;
        this.owner = "";
        this.websiter = "";
        this.remark = "";
        this.flie = "";
        this.fileUrl = "";
        document.getElementById("file").value == "";
        setTimeout(this.shut, 3000);
      }
    },
  },
  created() {
    this.getTableList();
    this.getNowFormatDate();
  },
  mounted() {
    document.addEventListener("click", this.clickBlankHandler);
  },
  destroyed() {
    document.removeEventListener("click", this.clickBlankHandler);
  },
};
</script>

<style lang="scss" scoped>
.isshow {
  width: 1000px;
  flex: 1 0 1020px;
}
.centerList {
  flex: 1;
  box-sizing: border-box;
  border-right: 1px solid #eceff1;
  position: relative;
  .centerList-title {
    height: 45px;
    text-align: center;
    line-height: 45px;
    font-size: $font-size-medium;
    font-family: PingFang SC;
    font-weight: 500;
    color: $color-text-gray-s;
    border-bottom: 1px solid #eceff1;
    position: relative;

    .copyrightIncome-item {
      width: 130px;
      height: 37px;
      color: #4587ff;
      border-radius: 3px;
      font-size: $font-size-small;
      font-family: PingFang SC;
      font-weight: 400;

      background: none;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
    }

    .start {
      color: $color-text-white;
      background: $color-bg-heightlight;
    }
  }
  ::v-deep .el-table {
    overflow: visible;
    .el-table__body-wrapper {
      overflow: visible;
    }
    .el-table__cell {
      overflow: hidden;
      &:last-child {
        overflow: visible;
        .cell {
          overflow: visible;
        }
      }
    }
    .cell {
      overflow: visible;
      position: relative;
      .states {
        position: absolute;
        left: 30px;
        top: 50%;
        width: 50px;
        height: 18px;
        margin-top: -9px;
        font-size: 12px;
        font-weight: 800;
        text-align: center;
        line-height: 18px;
        color: #fff;
        z-index: 1;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }

      .gray {
        &::before {
          background: url("~assets/images/rhombus-gray.png") no-repeat;
        }
        color: #999;
      }
      .green {
        &::before {
          background: url("~assets/images/rhombus-green.png") no-repeat;
        }
      }
      .orange {
        &::before {
          background: url("~assets/images/rhombus-orange.png") no-repeat;
        }
      }
      .eparsed {
        width: 62px;
        &::before {
          background: url("~assets/images/rhombus-red.png") no-repeat;
        }
      }
      .hasparse {
        width: 75px;
        &::before {
          background: url("~assets/images/rhombus-red-long.png") no-repeat;
        }
      }

      .operation {
        @include flex-start;
        > span {
          flex-shrink: 0;
          width: 14px;
          height: 14px;
          cursor: pointer;
          margin-right: 10px;
        }
        .more {
          position: relative;
          @include backgroundGroup("~assets/images/more.png");
          .submenu {
            @include noselect;
            &.submenu-active {
              @include flex-center(column);
              justify-content: normal;
              align-items: normal;
            }
            display: none;
            position: absolute;
            z-index: 1000;
            top: 20px;
            left: 0px;
            width: 114px;
            padding: 8px 0;
            background: #ffffff;
            box-shadow: 0px 4px 8px 1px rgba(31, 34, 40, 0.17);
            border-radius: 5px;
            box-sizing: border-box;
            span {
              padding: 7px 15px 6px;
              font-size: 12px;
              font-weight: 500;
              color: #999999;
              line-height: 1;
              cursor: pointer;
              &:hover {
                color: #666;
                background-color: #f5f6fa;
              }
            }
          }
        }
        .more:hover {
          @include backgroundGroup("~assets/images/more-blue.png");
        }
        .delete {
          @include backgroundGroup("~assets/images/delete.png");
        }
        .delete:hover {
          @include backgroundGroup("~assets/images/delete-blue.png");
        }
        .operationIcon {
          width: 15px;
          height: 15px;
          cursor: pointer;
          @include backgroundGroup("../../assets/images/shelves.png");
        }

        .no-operationIcon {
          width: 15px;
          height: 15px;
          cursor: pointer;
          @include backgroundGroup("../../assets/images/no-shelves.png");
        }
      }
      .fileName {
        cursor: pointer;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        align-items: center;
      }
      // .author,
      // .price {
      //   justify-content: space-between;
      // }
      .price,
      .author,
      .publishTime {
        display: flex;
        align-items: center;
        .modifyIcon {
          margin-left: 5px;
          width: 13px;
          height: 12px;
          cursor: pointer;
          @include backgroundGroup("../../assets/images/modify.png");
        }
      }
    }
  }
  ::v-deep .el-pagination {
    text-align: center;
    margin: 20px 0;
    .btn-prev,
    .btn-next {
      span {
        min-width: 60px;
      }
    }
  }

  .text {
    margin-bottom: 20px;
  }

  .upimg {
    color: #4587ff;
    border: 1px solid #dcdfe6;
    padding: 0 0 0 15px;
    cursor: pointer;
  }

  .select {
    display: flex;
    justify-content: center;
    align-items: center;
    .text {
      margin-bottom: 0;
      height: 100%;
      flex: 20%;
    }
  }

  .addauthor_box {
    display: flex;

    .placeholder {
      // padding: 20px;
      flex: 15%;
    }

    .addauthor {
      margin: 20px 0 10px 0;
      flex: 80%;
      text-align: left;
      color: #4587ff;
      cursor: pointer;
    }
  }

  // 修改授权价格弹窗
  .priceVisible {
    .cash {
      .text {
        margin: 10px 0;
        line-height: 20px;
        color: #000;
      }
    }

    .shape {
      margin: 20px 0;
    }
    .checked {
      margin-top: 10px;
      .checked_box {
        .checkbox_content {
          width: 99%;
          display: inline-grid;
          white-space: pre-line;
          word-wrap: break-word;
          overflow: hidden;
          line-height: 20px;
        }
      }
      .el-checkbox {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 10px 0 0 0;
      }

      .el-input {
        margin-top: 20px;
        ::v-deep .el-input__inner::-webkit-outer-spin-button,
        ::v-deep .el-input__inner::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }

        ::v-deep .el-input__inner[type="number"] {
          -moz-appearance: textfield;
        }
      }
    }
  }

  .no-document {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 200px 0;
    img {
      margin-bottom: 20px;
    }
  }

  .success {
    width: 391px;
    background: #ffffff;
    border: 1px solid #4587ff;
    border-radius: 22px;
    position: absolute;
    top: 600px;
    left: 41%;
    box-sizing: border-box;
    padding: 16px 21px;
    span {
      width: 349px;
      height: 67px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 26px;
    }
    img {
      position: relative;
      top: 4px;
      margin-right: 10px;
    }
  }

  // 详情弹窗
  .addFil {
    margin: 0 auto 50px;
    width: 70%;
    .box {
      margin-top: 20px;

      &:nth-child(1) {
        margin-top: 0;
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;

        display: flex;

        span {
          &:nth-child(2) {
            flex: 1;
          }
        }

        .download {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }
  }

  // 版权真实性承诺样式
  .copyrightVisible {
    .text {
      line-height: 20px;
      margin-bottom: 20px;
    }

    .signature_box {
      display: flex;
      justify-content: center;
      align-items: center;

      .signature_text {
        flex: 20%;
      }

      .signature {
        padding: 10px;
        border: 1px solid #e3e3e3;
        flex: 80%;
        color: #4587ff;
        cursor: pointer;
      }
    }

    .authorSignatureImg {
      margin: 0 auto;
      display: block;
      width: 330px;
      height: 190px;
    }

    .commitment_box {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;

      .commitmentText {
        flex: 20%;
      }

      .commitmentDate {
        flex: 25%;
      }

      .commitmentTime {
        flex: 55%;
      }
    }
  }

  // 上传弹窗
  .upload {
    ::v-deep .el-dialog {
      width: 55%;
      min-height: 340px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);

      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        display: flex;
        align-items: center;

        .el-dialog__title {
          margin-left: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }

        .el-dialog__headerbtn {
          top: 15px;
        }
      }

      .el-dialog__body {
        padding: 27px 30px 0px;

        .content-text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;

          .title {
            text-align: center;
          }

          .demand_box {
            display: flex;
            margin-top: 20px;
            align-items: center;
            .demand_text {
              width: 110px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
            }

            .demand_inp {
              cursor: pointer;
              flex: 1;
              padding: 10px;
              color: #4587ff;
              border: 1px solid #e3e3e3;
            }
          }

          .choose_data_box {
            display: flex;
            margin-top: 20px;

            .choose_text {
              width: 110px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;

              span {
                color: red;
              }
            }

            .choose_radio {
              .choose_item {
                display: flex;
                align-items: center;
                margin-bottom: 5px;

                input {
                  cursor: pointer;
                }

                label {
                  padding-left: 10px;
                  cursor: pointer;
                }
              }
            }
          }

          .choose_price_box {
            display: flex;
            margin-top: 20px;

            .choose_text {
              width: 110px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;

              span {
                color: red;
              }
            }

            .choose_radio {
              flex: 1;
              .choose_item {
                display: flex;
                align-items: center;
                margin-bottom: 5px;

                input {
                  cursor: pointer;
                }

                label {
                  padding-left: 10px;
                  cursor: pointer;
                }
              }

              .el-input {
                margin-top: 5px;
                .el-input__inner {
                  border-radius: 0px;
                }

                .el-input__inner::-webkit-outer-spin-button,
                .el-input__inner::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                }

                .el-input__inner[type="number"] {
                  -moz-appearance: textfield;
                }
              }
            }
          }
        }
      }
    }
  }

  // 版权文字作品上传须知弹窗
  .uploadNotice {
    ::v-deep .el-dialog {
      margin-top: 5vh !important;
      width: 680px;
      min-height: 340px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);

      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        display: flex;
        align-items: center;

        .el-dialog__title {
          margin-left: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }

        .el-dialog__headerbtn {
          top: 15px;
        }
      }

      .el-dialog__body {
        padding: 20px 30px 20px;
        .content-text {
          line-height: 2;
          .title {
            text-indent: 2em;
            font-size: 14px;
            font-weight: 800;
          }

          .text_box,
          .sequence_txt {
            text-indent: 2em;
          }
        }
      }
    }
  }
}

::v-deep .el-table__empty-block {
  display: none;
}
.claim {
  width: 340px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  position: fixed;
  top: 320px;
  left: 750px;
  .close {
    margin-top: 10px;
    margin-left: 317px;
    cursor: pointer;
  }
  .renzheng {
    display: flex;
    align-items: center;
    margin-left: 84px;
    margin-top: 41px;
    img {
      display: block;
      margin-right: 9px;
    }
    span {
      display: block;
      width: 160px;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .xuanze {
    display: flex;
    margin-top: 60px;
    .yes {
      width: 64px;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4587ff;
      margin-left: 54px;
      margin-right: 50px;
      cursor: pointer;
    }
    .no {
      margin-left: 65px;
      height: 15px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4587ff;
      cursor: pointer;
    }
    .xian {
      width: 1px;
      height: 20px;
      border-left: 1px solid #e8e8e8;
    }
  }
}
.claimn {
  z-index: 10;
  width: 428px;
  background: #ffffff;
  border: 0px solid #818790;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  position: fixed;
  top: 220px;
  left: 650px;
  .claimnNav {
    display: flex;
    width: 427px;
    height: 42px;
    background: #f4f6f7;
    .title {
      display: flex;
      align-items: center;
      margin-left: 10px;
      span {
        margin-left: 5px;
      }
    }
    .title_x {
      position: absolute;
      top: 14px;
      right: 10px;
      cursor: pointer;
      width: 14px;
      height: 14px;
    }
  }
  .wangzhi {
    margin-top: 21px;
    margin-left: 25px;
    cursor: pointer;
    label {
      width: 55px;
      height: 14px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .w-title {
    height: 14px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
    display: block;
    margin-top: 20px;
    margin-left: 30px;
  }
  .shuruw {
    width: 366px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #cecece;
    border-radius: 5px;
    margin-top: 20px;
    margin-left: 30px;
    box-sizing: border-box;
    padding: 18px 20px;
    display: block;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  textarea {
    width: 366px;
    height: 100px;
    background: #ffffff;
    border: 1px solid #cecece;
    border-radius: 5px;
    margin-top: 20px;
    margin-left: 30px;
    box-sizing: border-box;
    padding: 18px 20px;
    display: block;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    resize: none;
  }
  .file-tip {
    width: 366px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #cecece;
    border-radius: 5px;
    margin-top: -50px;
    margin-left: 30px;
    box-sizing: border-box;
    padding: 18px 20px;
    display: block;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #4587ff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .botton {
    display: flex;
    margin-left: 240px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
