import { render, staticRenderFns } from "./rightList.vue?vue&type=template&id=3aa71707&scoped=true&"
import script from "./rightList.vue?vue&type=script&lang=js&"
export * from "./rightList.vue?vue&type=script&lang=js&"
import style0 from "./rightList.vue?vue&type=style&index=0&id=3aa71707&lang=scss&scoped=true&"
import style1 from "./rightList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aa71707",
  null
  
)

export default component.exports