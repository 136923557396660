<template>
  <div class="rightList" :class="!isShow ? 'w' : ''">
    <div class="rightlist_show" :class="isShow ? 'noshow' : 'show'">
      <div class="rightList-title">
        <div class="left-line"></div>
        <i class="title-icon"></i>
        <div class="title-text">
          <span>{{ username }}</span
          >的小金库
        </div>
        <div class="right-line"></div>
      </div>
      <div class="rightList-subTitle">
        蚊子腿是一种态度 · 关键还是喜欢这种一劳永逸的感觉 ·
        挥一挥衣袖就不去想流芳百世了
      </div>
      <div class="center-box">
        <div
          @click="handleCenter(item.id)"
          class="centerBox-item"
          v-for="item in centerList"
          :key="item.id"
          :class="item.id == 1 ? 'purple' : 'green'"
        >
          <i
            class="centerBox-icon"
            :class="item.id == 1 ? 'integralIcon' : 'rankingList'"
          ></i>
          <div class="textBox">
            <div class="textBox-title">{{ item.title }}</div>
            <div class="textBox-num" v-if="item.id == 1">{{ item.num }}</div>
            <div class="textBox-num" v-else>
              <span>{{ item.num }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="last-box">
        <div class="last-box-item" v-for="item in lastList" :key="item.id">
          <i
            class="last-box-icon"
            :class="item.id == 1 ? 'cash' : 'character'"
          ></i>
          <div class="last-box-text">
            {{ item.id == 1 ? "现金" : "字符包" }}
          </div>
          <div class="last-box-num">
            {{ item.id == 1 ? `${item.num} 元` : `${item.num} 字` }}
          </div>
          <div class="last-box-record" @click="revenueRecord(item.id)">
            <i class="last-box-recordIcon"></i>收支记录
          </div>
          <div class="last-box-extract" @click="handledialog(item.id)">
            {{ item.id == 1 ? "提取现金" : "兑换现金" }}
          </div>
        </div>
      </div>
    </div>

    <!-- 提示弹窗 -->
    <el-dialog title="收支明细记录" :visible.sync="dialogTips" class="tips">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="收入记录" name="revenue">
          <el-table height="440" :data="tableData" style="width: 100%">
            <el-table-column
              prop="remark"
              show-overflow-tooltip
              label="内容"
              width="360"
            >
            </el-table-column>
            <el-table-column prop="quantity" label="数量/金额">
            </el-table-column>
            <el-table-column
              prop="fileName"
              show-overflow-tooltip
              label="文件名"
            >
            </el-table-column>
            <el-table-column prop="createTime" label="时间" width="180">
            </el-table-column>
          </el-table>
          <el-pagination
            v-show="tableData.length !== 0"
            background
            layout="prev, pager, next"
            prev-text="上一页"
            next-text="下一页"
            :page-size="limit"
            :current-page="page"
            @prev-click="lastPageChange"
            @next-click="nextPageChange"
            @current-change="currentChange"
            :total="total"
          >
          </el-pagination>
        </el-tab-pane>

        <el-tab-pane label="支出记录" name="expend">
          <el-table height="440" :data="tableData" style="width: 100%">
            <el-table-column prop="remark" label="内容" width="360">
            </el-table-column>
            <el-table-column prop="quantity" label="数量/金额">
            </el-table-column>
            <el-table-column prop="createTime" label="时间" width="180">
            </el-table-column>
          </el-table>
          <el-pagination
            v-show="tableData.length !== 0"
            background
            layout="prev, pager, next"
            prev-text="上一页"
            next-text="下一页"
            :page-size="limit"
            :current-page="page"
            @prev-click="lastPageChange"
            @next-click="nextPageChange"
            @current-change="currentChange"
            :total="total"
          >
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 字符提现或者现金提现弹窗 -->
    <el-dialog
      :title="val == 1 ? '提取现金' : '字符包兑换现金'"
      :visible.sync="dialogVisible"
      @close="handleClose"
      width="20%"
    >
      <div class="cash" v-show="val == 1">
        <i class="last-box-icon icon_cash"></i>

        现金余额：
        <div class="text">{{ lastList[val - 1].num }}</div>
        元
      </div>

      <div class="cahracter" v-show="val != 1">
        <i class="last-box-icon icon_cahracter"></i>

        字符：
        <div class="text">{{ lastList[val - 1].num }}</div>
        字
      </div>
      <div class="shape">
        <i class="last-box-icon icon_shape"></i>

        选择{{ val != 1 ? "兑现" : "提取" }}方式
      </div>

      <div class="checked">
        <el-checkbox v-model="checked" @change="handleChecked(1)">
          全部{{ val != 1 ? "兑现" : "提取" }}（
          <span class="text">{{
            val == 1
              ? lastList[val - 1].num
              : ((lastList[val - 1].num / 10000) * 2.5).toFixed(2)
          }}</span>
          元 ）
        </el-checkbox>
        <el-checkbox v-model="inputshow" @change="handleChecked(2)">
          自定义{{ val != 1 ? "兑现" : "提取"
          }}<span v-show="input && val != 1"> （</span>
          <span class="text" v-show="input && val != 1">{{
            ((input / 10000) * 2.5).toFixed(2)
          }}</span>

          <span v-show="input && val != 1"> 元 ）</span>
        </el-checkbox>

        <el-input
          v-show="inputshow"
          v-model="input"
          type="Number"
          @change="handleSumChange"
          :placeholder="
            val == 1
              ? '请输入金额，超出现有金额视为全部提取'
              : '请输入字符，超出现有字符视为全部提取'
          "
        ></el-input>
      </div>

      <div class="shape" v-show="val == 1">
        <i class="last-box-icon icon_shape"></i>
        选择到账方式
      </div>

      <div class="radio" v-show="val == 1">
        <el-checkbox-group
          v-model="wayList"
          @change="handleCheckedCitiesChange"
        >
          <!-- <el-checkbox label="提现至微信"></el-checkbox> -->
          <el-checkbox label="提现至支付宝"></el-checkbox>
          <!-- <el-checkbox label="提现至银行卡"></el-checkbox> -->
        </el-checkbox-group>

        <div class="txt">账号实名</div>
        <el-input
          v-model="alipayName"
          placeholder="请输入提现账号实名"
        ></el-input>
        <div class="txt">提现账号</div>
        <el-input
          v-model="alipayAccount"
          placeholder="请输入提现账号"
        ></el-input>
      </div>

      <div class="shape" v-show="val == 1">
        提现须知：预计将于1个工作日内到账。
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="redeemedForCash">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 排名和积分弹窗  centerVal 1积分 2排名  -->
    <el-dialog :visible.sync="centerVisible" width="20%" center>
      <div class="center">
        <div class="title">
          <i
            class="last-box-icon"
            :class="centerVal == 1 ? 'icon_integral' : 'icon_ranking'"
          ></i>
          {{ centerVal == 1 ? "权益积分:" : "江湖排名:" }}
          <div class="integral" v-show="centerVal != 1">
            <div class="text">{{ centerList[1].num }}</div>
          </div>
          <div class="text" v-show="centerVal == 1">
            {{ centerList[0].num }}
          </div>
        </div>
        <div class="text_ranking">
          {{
            centerVal == 1
              ? "基于原创内容获得的字符包兑换现金，2.5元/万字符,累计的钱1元/积分。VIP/SVIP会员及系统赠送的字符包、不参与积分累积。"
              : "基于原创内容获得的字符包兑换现金，2.5元/万字符,累计的钱1元/积分。VIP/SVIP会员及系统赠送的字符包、不参与积分累积。"
          }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn_box" v-if="centerVal == 1">
          <div class="confirm" @click="centerVisible = false">
            {{ centerVal == 1 ? "确 定" : "年度百强" }}
          </div>
          <div class="placeholder"></div>
          <div class="cancel" @click="centerVisible = false">
            {{ centerVal == 1 ? "取 消" : "月度十强" }}
          </div>
        </div>
      </span>
    </el-dialog>

    <div class="receive_box" @click="handleIsShow">
      <img
        class="receive_img"
        :class="isShow ? 'rotate' : ''"
        src="../../assets/images/detail_slide_left.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import {
  findCopyrightIntegralOrder,
  findRevenue,
  findCopyrightIntegral,
  findRevenueExpendInfo,
  exchangeMoney,
  addCashMoney,
} from "api/copyrightIncome";
export default {
  data() {
    return {
      centerVal: 1, //1积分  2排名
      centerVisible: false, // 排名和积分弹窗
      val: 1, //1现金  2字符包
      cahracter: false,
      wayList: ["提现至支付宝"],
      checked: true,
      inputshow: false, // 输入框显示隐藏
      input: "", // 提现金额
      alipayName: "", //支付宝姓名
      alipayAccount: "", //支付宝账户
      dialogVisible: false, // 对话框显示隐藏
      username: "测试",
      dialogTips: false, //提示弹窗
      activeName: "revenue",
      reType: 1,
      page: 1, //页码
      limit: 10, //一页10条数据
      total: 0, //总条数
      tableData: [], //表格数据,
      isShow: true,
      projectType: 1,
      centerList: [
        {
          id: 1, //1权益积分 2排行榜
          num: 0,
          title: "权益积分",
        },
        {
          id: 2,
          num: null,
          title: "江湖排行榜",
        },
      ],
      lastList: [
        {
          id: 1, //1现金  2字符包
          num: 0,
        },
        {
          id: 2,
          num: 0,
        },
      ],
    };
  },
  methods: {
    handleClick(tab) {
      // console.log(tab);
      // console.log(tab.index);
      if (tab.index == 0) {
        this.reType = 1;
      } else {
        this.reType = 2;
      }

      this.getRevenueExpendInfo();
    },
    handleCenter(val) {
      this.centerVal = val;
      this.centerVisible = true;
    },

    // 字符提现或者现金提现
    handledialog(val) {
      // this.dialogVisible = true;
      // this.val = val;
      this.$router.push({
        path: "/accountRecords",
      });
    },

    // 自定义提现额度或者全额
    handleChecked(val) {
      val == 1
        ? ((this.checked = true), (this.inputshow = false))
        : ((this.checked = false), (this.inputshow = true));
    },
    // 提现类型
    handleCheckedCitiesChange(val) {
      this.wayList = [val[val.length - 1]];
    },
    // 收支记录按钮
    revenueRecord(id) {
      this.projectType = id;
      this.page = 1;
      this.tableData = [];
      this.dialogTips = true;
      this.getRevenueExpendInfo();
    },
    // 输入金额  输入字符 inputChange 事件
    handleSumChange() {
      if (this.input > this.lastList[this.val - 1].num && this.val === 1) {
        this.input = this.lastList[this.val - 1].num;
      } else if (
        this.input > this.lastList[this.val - 1].num &&
        this.val === 2
      ) {
        this.input = this.lastList[this.val - 1].num;
      }
    },

    //获取收支记录
    async getRevenueExpendInfo() {
      const data = {
        projectType: this.projectType,
        reType: this.reType,
        page: this.page,
        limit: this.limit,
      };
      const res = await findRevenueExpendInfo(data);
      if (res.code === 200) {
        // console.log(res.data.data);
        this.tableData = res.data.data;

        this.total = res.data.total;
      } else {
        this.$message({
          type: "error",
          message: "获取收支记录" + res.message,
        });
      }
    },
    //获取排名
    async getRanking() {
      const res = await findCopyrightIntegralOrder();
      if (res.code === 200) {
        if (res.data == 0 || res.data >= 10000) {
          this.centerList[1].num = "暂无";
        } else {
          this.centerList[1].num = "第" + res.data + "名";
        }
      } else {
        this.$message({
          type: "error",
          message: "获取排名" + res.message,
        });
      }
    },
    //获取现金、字符数量
    async getRevenue() {
      const res = await findRevenue();
      if (res.code === 200) {
        let info = res.data;
        if (info == "" || info == null) {
          this.lastList[0].num = 0;
          this.lastList[1].num = 0;
        } else {
          this.lastList[0].num =
            res.data.copyrightIncome + res.data.practiceIncome;
          this.lastList[1].num = res.data.incomeCharacter;
        }
        this.username = res.message;
      } else {
        this.$message({
          type: "error",
          message: "获取现金、字符数量" + res.message,
        });
      }
    },
    //获取积分
    async getintegral() {
      const res = await findCopyrightIntegral();
      if (res.code === 200) {
        if (res.data.integral == 0) {
          this.centerList[0].num = "暂无";
        } else {
          this.centerList[0].num = res.data.integral;
        }
      } else {
        this.$message({
          type: "error",
          message: "获取积分" + res.message,
        });
      }
    },
    async redeemedForCash() {
      let money;
      if (this.checked && this.val == 1) {
        money = this.lastList[this.val - 1].num;
      } else if (!this.checked && this.val == 1) {
        money = this.input;
      } else if (this.checked && this.val != 1) {
        money = ((this.lastList[this.val - 1].num / 10000) * 2.5).toFixed(2);
      } else if (!this.checked && this.val != 1) {
        money = ((this.input / 10000) * 2.5).toFixed(2);
      }

      if (money < 1) {
        return this.$message({
          type: "error",
          message: this.val == 1 ? "提取金额不能小于1" : "兑换金额不能小于1",
        });
      }

      if (this.val === 1) {
        if (!this.alipayName) {
          return this.$message({
            type: "error",
            message: "请输入账号实名!",
          });
        }

        if (!this.alipayAccount) {
          return this.$message({
            type: "error",
            message: "请输入账号!",
          });
        }

        const data = {
          extractType: this.checked ? 1 : 2,
          extractNum: money,
          arriveType: "2",
          realName: this.alipayName,
          arriveAccount: this.alipayAccount,
        };
        const res = await addCashMoney(data);
        if (res.code === 200) {
          this.alipayName = "";
          this.alipayAccount = "";
          this.checked = true;
          this.inputshow = false;
          this.input = "";
          this.dialogVisible = false;
          this.$message({
            type: "success",
            message: "提现成功",
          });
        } else {
          this.$message({
            type: "error",
            message: "提现失败",
          });
        }
      } else {
        const data = {
          ptype: this.val,
          money,
        };
        console.log(data);
        const res = await exchangeMoney(data);
        if (res.code === 200) {
          this.checked = true;
          this.input = "";
          this.inputshow = false;
          this.dialogVisible = false;
          this.$message({
            type: "success",
            message: "兑换成功",
          });
        } else {
          this.$message({
            type: "error",
            message: "兑换失败",
          });
        }
      }

      this.getRevenue();
    },

    //  上一页
    lastPageChange(val) {
      this.page = val;
    },
    // page改变触发
    currentChange(cur) {
      this.page = cur;
      this.getRevenueExpendInfo();
    },
    // 下一页
    nextPageChange(val) {
      this.page = val;
    },

    handleIsShow() {
      this.isShow = !this.isShow;
      this.$emit("func", this.isShow);
    },

    // 字符提现或者现金提现弹窗关闭回调
    handleClose() {
      // this.isShow = !this.isShow;
      this.input = "";
      this.checked = true;
      this.inputshow = false;
      this.alipayName = "";
      this.alipayAccount = "";
    },
  },
  created() {
    this.getRanking();
    this.getRevenue();
    this.getintegral();
  },
};
</script>

<style lang="scss" scoped>
.w {
  width: 30px !important;
  position: relative;
}
.rightList {
  position: relative;
  width: 424px;
  .rightList-title {
    margin-top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    .left-line {
      width: 85px;
      height: 12px;
      @include backgroundGroup("../../assets/images/left-line.png");
      margin-right: 16px;
    }
    .title-icon {
      width: 12px;
      height: 12px;
      @include backgroundGroup("../../assets/images/titleIcon.png");
      margin-right: 10px;
    }
    .title-text {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      span {
        font-size: 14px;
        font-weight: bold;
        margin-right: 10px;
      }
    }
    .right-line {
      width: 85px;
      height: 12px;
      @include backgroundGroup("../../assets/images/right-line.png");
      margin-left: 15px;
    }
  }
  .rightList-subTitle {
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 1.5;
  }
  .center-box {
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
    .centerBox-item {
      width: 175px;
      height: 70px;
      display: flex;
      align-items: center;
      .centerBox-icon {
        width: 21px;
        height: 21px;
        margin-left: 30px;
      }
      .integralIcon {
        @include backgroundGroup("../../assets/images/integralIcon.png");
      }
      .rankingList {
        @include backgroundGroup("../../assets/images/rankingList.png");
      }
      .textBox {
        margin-left: 13px;
        line-height: 25px;
        .textBox-title {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        .textBox-num {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
          span {
            margin: 0 5px;
          }
        }
      }
    }
    .purple {
      @include backgroundGroup("../../assets/images/purple-bgc.png");
    }
    .green {
      @include backgroundGroup("../../assets/images/green-bgc.png");
    }
  }
  .last-box {
    padding: 0 10px;
    box-sizing: border-box;
    .last-box-item {
      box-sizing: border-box;
      width: 100%;
      height: 72px;
      background: #ffffff;
      border: 1px solid #eef1f5;
      border-radius: 10px;
      margin-top: 20px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      .last-box-icon {
        width: 38px;
        height: 39px;
        margin-right: 7px;
      }
      .cash {
        @include backgroundGroup("../../assets/images/cashIcon.png");
      }
      .character {
        @include backgroundGroup("../../assets/images/characterIcon.png");
      }
      .last-box-text {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #4587ff;
        width: 60px;
      }
      .last-box-num {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        width: 70px;
      }
      .last-box-record {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #4587ff;
        cursor: pointer;
        i {
          width: 14px;
          height: 17px;
          margin-right: 6px;
          @include backgroundGroup("../../assets/images/recordIcon.png");
        }
      }
      .last-box-extract {
        margin-left: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #4587ff;
        cursor: pointer;
      }
    }
  }

  .cahracter {
    font-size: 14px;
    display: flex;
    align-items: center;
    .icon_cahracter {
      width: 14px;
      height: 14px;
      margin-right: 6px;
      @include backgroundGroup("../../assets/images/cahracter.png");
    }
    .text {
      color: #498aff;
      font-size: 24px;
      margin-right: 6px;
      font-weight: 800;
    }
  }

  .cash {
    font-size: 14px;
    display: flex;
    align-items: center;
    .icon_cash {
      width: 14px;
      height: 14px;
      margin-right: 6px;
      @include backgroundGroup("../../assets/images/cash.png");
    }
    .text {
      color: #eb441e;
      font-size: 24px;
      margin-right: 6px;
      font-weight: 800;
    }
  }

  .shape {
    margin-top: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    .icon_shape {
      width: 14px;
      height: 14px;
      margin-right: 6px;
      @include backgroundGroup("../../assets/images/shape.png");
    }
  }

  .radio {
    .txt {
      margin: 10px 0;
    }
    .el-input {
      margin: 10px 0;
    }
  }

  .checked {
    .el-checkbox {
      margin: 20px 0 0 20px;
    }

    .el-input {
      margin-top: 20px;
    }
    display: flex;
    flex-direction: column;

    .text {
      color: #eb441e;
    }
  }

  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    .el-checkbox {
      margin: 20px 0 0 20px;
    }
  }

  .center {
    .title {
      font-weight: 800;
      font-size: 16px;
      justify-content: center;
      display: flex;
      align-items: center;
      .icon_ranking {
        width: 19px;
        height: 19px;
        margin-right: 6px;
        @include backgroundGroup("../../assets/images/ranking.png");
      }

      .integral {
        display: flex;
      }
      .icon_integral {
        width: 19px;
        height: 19px;
        margin-right: 6px;
        @include backgroundGroup("../../assets/images/integral.png");
      }
      .text {
        color: #498aff;
        margin: 0 10px;
      }
    }
    .text_ranking {
      margin-top: 15px;
      line-height: 30px;
    }
  }

  .btn_box {
    display: flex;
    color: #4587ff;
    font-size: 16px;
    justify-content: space-between;

    .placeholder {
      width: 1px;
      height: 20px;
      background-color: #e8e8e8;
    }
    .confirm,
    .cancel {
      flex: 48%;
      cursor: pointer;
    }
  }
}
.tips {
  ::v-deep .el-dialog {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
    width: 950px;
    height: 650px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      padding: 12px 12px 0px;
      .el-dialog__title {
        font-size: $font-size-small;
        font-family: PingFang SC;
        font-weight: 400;
        color: $color-text-gray;
      }
      .el-dialog__headerbtn {
        top: 12px;
        right: 12px;
      }
    }
    .el-dialog__body {
      padding: 15px 15px;
      // overflow: auto;
      // height: 80%;
      // .revenueData {
      //   position: relative;
      //   bottom: 0;
      // }

      .content {
        font-size: $font-size-medium-x;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-black;
        line-height: 26px;
        display: flex;
        span {
          color: #eb441e;
        }
        .content-icon {
          width: 31px;
          height: 18px;
          @include backgroundGroup("../../assets/images/del-error.png");
          transform: translateY(4px);
          margin-right: 9px;
        }
      }

      .footer {
        display: flex;
        justify-content: space-between;
        margin-top: 33px;
        .footer-btn {
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-bg-heightlight;
          cursor: pointer;
        }
      }
    }
  }
}
.el-table__empty-block {
  min-height: 400px;
}

.receive_box {
  position: absolute;
  left: 5px;
  top: 37%;

  .receive_img {
    cursor: pointer;
    width: 10px;
    height: 15px;
  }
}

.rotate {
  animation: receive_img 0.3s;
  animation-fill-mode: both;
}

.show {
  animation: show 0.3s;
  animation-fill-mode: both;
}
.noshow {
  animation: noshow 0.3s;
  animation-fill-mode: both;
}

@keyframes receive_img {
  0% {
  }
  100% {
    // transform: ;
    transform: rotate(180deg);
  }
}

@keyframes show {
  0% {
  }
  100% {
    // transform: ;
    transform: translateX(420px);
  }
}

@keyframes noshow {
  0% {
    transform: translateX(420px);
  }
  100% {
    // transform: ;
    transform: translateX(0px);
  }
}
</style>
<style lang="scss">
.el-tooltip__popper {
  max-width: 400px;
  margin-left: 80px;
}
.el-tooltip__popper.is-dark {
  background: #fff !important;
  color: #606266 !important;
  font-size: 14px;
  box-shadow: 0 3px 3px 3px rgba(151, 151, 158, 0.5);
}
</style>
