<template>
  <div class="wm-antiplagiarism-copyrightIncome">
    <div class="copyrightIncome-btnBox" v-if="false">
      <button class="copyrightIncome-item start">上传版权作品</button>
      <button
        class="copyrightIncome-item"
        @click="
          isShowUpload();
          title = '版权Word文档上传';
          fileType = 1;
        "
      >
        选择Word文档
      </button>
      <button
        class="copyrightIncome-item"
        @click="
          isShowUpload();
          title = '版权图片上传';
          fileType = 2;
        "
      >
        选择图片
      </button>
      <button
        class="copyrightIncome-item"
        @click="
          isShowUpload();
          title = '版权视频上传';
          fileType = 3;
        "
      >
        选择视频
      </button>
      <button
        class="copyrightIncome-item"
        @click="
          isShowUpload();
          title = '版权音频上传';
          fileType = 4;
        "
      >
        选择音频
      </button>
    </div>
    <center-list @fuSublime="sublime" ref="getlist" :isShowRight="isShowRight"></center-list>
    <right-list @func="isShowRightList"></right-list>

    <!--版权Word文档上传附件弹出框 -->
    <el-dialog v-dialogDrag :title="title" :visible.sync="uploadVisible" class="upload">
      <div class="content-text">
        <div>
          请使用统一规范的文件名，文件名由“作者、主标题、文献类型、时间”四部分组成。各部分之间用圆点“.”隔开。
        </div>
      </div>
      <UploadBigFile
        class="uploadSlot"
        :parentId="parentId"
        :columnType="columnType"
        :fileType="fileType"
      >
      </UploadBigFile>
    </el-dialog>

    <!-- 版权真实性承诺 -->
    <el-dialog title="版权真实性承诺" :visible.sync="copyrightVisible" class="copyrightVisible">
      <div class="text">本人承诺所上传作品均为本人真实创作的版权作品，愿意为此承担法律责任。</div>

      <div class="signature_box">
        <div class="signature_text">承诺人签名</div>
        <div
          v-show="!authorSignatureImg"
          class="signature"
          @click="(signaTureShow = true), (authorSignatureImg = '')"
        >
          点击签名
        </div>
      </div>
      <img
        @click="(signaTureShow = true), (authorSignatureImg = '')"
        v-show="authorSignatureImg"
        :src="authorSignatureImg"
        class="authorSignatureImg"
      />
      <div class="commitment_box">
        <div class="commitmentText">承诺时间</div>
        <div class="commitmentDate">{{ currentdate }}</div>
        <div class="commitmentTime">{{ currenttime }}</div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleAddCommitment">确定</el-button>
        <el-button @click="copyrightVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 签名画布组件 -->
    <el-dialog title="签名" :visible.sync="signaTureShow" width="50%">
      <Signature
        class="SignatureCanvas"
        :width="500"
        :height="300"
        @handleSubmit="handleSignature"
      />
    </el-dialog>

    <!-- 提示弹窗 -->
    <el-dialog :visible.sync="centerVisible" width="20%" center>
      <div class="center">
        <div class="title">提示</div>
        <div class="text_ranking">是否签名授权承诺</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn_box">
          <div class="confirm" @click="checkUserName()">确 定</div>
          <div class="placeholder"></div>
          <div class="cancel" @click="centerVisible = false">取 消</div>
        </div>
      </span>
    </el-dialog>

    <!-- 提示弹窗 -->
    <!-- <el-dialog :visible.sync="realNameDialog" width="20%" center>
      <div class="center">
        <div class="title">提示</div>
        <div class="text_ranking">未实名,是否前去实名认证</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn_box">
          <div
            class="confirm"
            @click="
              $router.push('/copyrightService/realName/fillPersonalInfo');
              realNameDialog = false;
            "
          >
            确 定
          </div>
          <div class="placeholder"></div>
          <div class="cancel" @click="realNameDialog = false">取 消</div>
        </div>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import centerList from "components/copyright-income/centerList";
import rightList from "components/copyright-income/rightList";
import UploadBigFile from "components/disk/CopyrightIncomeUpload.vue";
import Signature from "components/Signature";
import {
  commitment,
  addCommitment,
  // checkUserRealname,
} from "api/copyrightIncome";
export default {
  name: "CopyrightIncome",
  components: {
    centerList,
    rightList,
    UploadBigFile,
    Signature,
  },
  data() {
    return {
      // 实名弹窗
      realNameDialog: false,
      ShowUpload: false,
      uploadVisible: false, //上传作品弹窗
      fileType: 1,
      parentId: 0,
      columnType: 2, //类型  网盘传1  版权传2 存证3  溯源4
      title: "版权Word文档上传",
      centerVisible: false, // 提示弹窗
      copyrightVisible: false, //版权真实性承诺弹窗
      signaTureShow: false, // 显示签名组件
      authorSignatureImg: "", // 作者签名图片
      currentdate: "", // 当前日期
      currenttime: "", // 当期时间
      isShowRight: false,
    };
  },

  methods: {
    // 触发子组件 getLIst
    fuSublime() {
      this.$refs.getlist.ziSublime();
    },
    sublime() {},

    // 校验授权承诺
    async checkAuthorization() {
      const res = await commitment();
      // console.log(res);
      if (res.code === 200) {
        this.uploadVisible = true;
      } else {
        this.copyrightVisible = true;
      }
    },

    async handleAddCommitment() {
      if (!this.authorSignatureImg) {
        return this.$message({
          type: "error",
          message: "请签名",
        });
      }
      const data = {
        content: "本人承诺所上传作品均为本人真实创作的版权作品，愿意为此承担法律责任",
        autograph: this.authorSignatureImg,
        startTime: this.currentdate + " " + this.currenttime,
      };
      const res = await addCommitment(data);
      // console.log(res);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "授权成功",
        });
        this.fuSublime();
        this.copyrightVisible = false;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 阻止上传弹窗
    async isShowUpload() {
      this.$store.commit("CHECK_USER_OPTIONS", {
        afterCheck: () => {
          this.checkAuthorization();
        },
      });
    },

    // 签名组件方法
    handleSignature(val) {
      this.signaTureShow = false;
      this.authorSignatureImg = val;
    },

    // 获取当前日期
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      var hour = date.getHours();
      var minu = date.getMinutes();
      var sec = date.getSeconds();

      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minu >= 0 && minu <= 9) {
        minu = "0" + minu;
      }
      if (sec >= 0 && sec <= 9) {
        sec = "0" + sec;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      this.currenttime = hour + ":" + minu + ":" + sec;
      this.currentdate = currentdate;
    },

    isShowRightList(data) {
      this.isShowRight = !data;
    },
  },

  created() {
    this.getNowFormatDate();
    // this.checkAuthorization();
  },
};
</script>

<style lang="scss" scoped>
.wm-antiplagiarism-copyrightIncome {
  overflow: hidden;
  height: 931px;
  border: 1px solid #eceff1;
  border-radius: 5px;
  display: flex;
  margin: 30px auto 50px;

  .copyrightIncome-btnBox {
    flex-shrink: 0;
    width: 149px;
    border-right: 1px solid #eceff1;
    text-align: center;

    .copyrightIncome-item {
      width: 100px;
      height: 37px;
      border: 1px solid $color-bg-heightlight;
      border-radius: 3px;
      font-size: $font-size-small;
      font-family: PingFang SC;
      font-weight: 400;
      color: $color-text-black;
      background: none;
      margin-top: 30px;
      cursor: pointer;
    }

    .start {
      color: $color-text-white;
      background: $color-bg-heightlight;
    }
  }

  // 版权真实性承诺样式
  .copyrightVisible {
    .text {
      line-height: 20px;
      margin-bottom: 20px;
    }

    .signature_box {
      display: flex;
      justify-content: center;
      align-items: center;

      .signature_text {
        flex: 20%;
      }

      .signature {
        padding: 10px;
        border: 1px solid #e3e3e3;
        flex: 80%;
        color: #4587ff;
        cursor: pointer;
      }
    }

    .authorSignatureImg {
      margin: 0 auto;
      display: block;
      width: 330px;
      height: 190px;
    }

    .commitment_box {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;

      .commitmentText {
        flex: 20%;
      }

      .commitmentDate {
        flex: 25%;
      }

      .commitmentTime {
        flex: 55%;
      }
    }
  }

  // 提示弹窗
  .center {
    .title {
      font-weight: 800;
      font-size: 16px;
      justify-content: center;
      display: flex;
      align-items: center;

      .integral {
        display: flex;
      }
    }

    .text_ranking {
      text-align: center;
      margin-top: 15px;
      line-height: 30px;
    }
  }

  .btn_box {
    display: flex;
    color: #4587ff;
    font-size: 16px;

    justify-content: space-between;

    .placeholder {
      width: 1px;
      height: 20px;
      background-color: #e8e8e8;
    }

    .confirm,
    .cancel {
      flex: 48%;
      cursor: pointer;
    }
  }

  // 上传弹窗
  .upload {
    ::v-deep .el-dialog {
      width: 45%;
      min-height: 340px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);

      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        display: flex;
        align-items: center;

        .el-dialog__title {
          margin-left: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }

        .el-dialog__headerbtn {
          top: 15px;
        }
      }

      .el-dialog__body {
        padding: 27px 30px 0px;

        .content-text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }
}
</style>
