<template>
  <!-- 上传器 -->
  <uploader
    ref="uploader"
    :options="options"
    :autoStart="false"
    :file-status-text="fileStatusText"
    @file-added="onFileAdded"
    @file-success="onFileSuccess"
    @file-error="onFileError"
    class="uploader-ui"
    :key="uploaderKey"
  >
    <uploader-unsupport></uploader-unsupport>
    <uploader-drop>
      <div>
        <uploader-btn
          id="global-uploader-btn"
          :attrs="attrs"
          :key="uploadBtnKey"
          ref="uploadBtn"
          >选择文件<i class="el-icon-upload el-icon--right"></i
        ></uploader-btn>
      </div>
      <div class="text">
        <span>温馨提示：</span>
        选择文件后，点击文件名，文献类型，来源，创作时间可自主修改
      </div>
    </uploader-drop>
    <uploader-list>
      <el-table
        :data="tableData"
        v-show="tableData.length"
        @cell-click="cellClick"
      >
        <el-table-column label="作者">
          <template>{{ $store.state.realNameData.cardName }}</template>
        </el-table-column>
        <el-table-column
          v-for="(col, ci) in column"
          :key="ci"
          :label="col.label"
          :prop="col.prop"
          :width="col.width"
        >
          <template slot-scope="{ row, row: { name }, $index }">
            <div class="content" :title="col.label == '文件名' ? name : ''">
              <template v-if="col.label == '文件名'">{{ name }}</template>
              <template v-if="col.label == '文献类型'">{{
                col.value
              }}</template>
              <template v-if="col.label == '来源'">写手智能科技数据库</template>
              <template v-if="col.label == '创作时间'">{{
                format(Date.now())
              }}</template>
            </div>
            <input
              ref="textinput"
              type="text"
              :class="['edit-cell', row.column[ci].edit ? 'show' : '']"
              v-model="row.column[ci].value"
              @keyup="editCellBlur($index, ci, $event)"
              @blur.stop="editCellBlur($index, ci, $event, true)"
              @click.stop
              v-if="col.type == 'text'"
            />
            <el-select
              ref="docselect"
              v-model="row.column[ci].value"
              :placeholder="docs[0].name"
              :class="['select-cell', row.column[ci].edit ? 'show' : '']"
              v-if="col.type == 'select'"
              @change="(cur) => selectChange(cur, $index, ci)"
            >
              <el-option
                v-for="(item, index) in docs"
                :key="index"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-date-picker
              ref="datepiker"
              v-model="row.column[ci].value"
              type="date"
              :placeholder="row.column[ci].value"
              :class="['date-cell', row.column[ci].edit ? 'show' : '']"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :clearable="false"
              v-if="col.type == 'date'"
              @change="(cur) => selectChange(cur, $index, ci)"
            >
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="大小">
          <template>1M</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <div class="operate">
              <span class="error" v-if="row.error">上传失败</span>
              <span class="el-icon-loading" v-if="row.isUploading()"></span>
              <span class="success" v-if="row.completed">上传成功</span>
              <span
                class="delete el-icon-close"
                @click="removeFile(row)"
              ></span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </uploader-list>
  </uploader>
</template>

<script>
import { ACCEPT_CONFIG } from '@/config/upload'
import SparkMD5 from 'spark-md5'
import { mergeFile } from '@/api/uploadFile'
import { getToken } from '@/utils/auth'
import { stringify } from 'qs'
import Vue from 'vue'
export default {
  props: {
    parentId: {
      type: Number,
      required: true,
    },
    columnType: {
      type: Number,
      required: true,
    },
    fileType: {
      type: Number,
      required: true,
    },
    money: {},
  },

  data() {
    let TokenObj = getToken()
    let Authorization = ''
    if (TokenObj) {
      TokenObj = JSON.parse(TokenObj)
      Authorization = `${TokenObj.token_type} ${TokenObj.access_token}`
    } else {
      Authorization = process.env.VUE_APP_DEFAULT_TOKEN
    }
    return {
      options: {
        //目标上传 URL，默认POST
        //target: "uploader/chunk",
        headers: { Authorization: Authorization },
        target: '/api/uploader/chunk',
        // target: process.env.VUE_APP_BASE_API+"api/uploader/chunk",
        //分块大小(单位：字节)
        chunkSize: '2048000',
        //上传文件时文件内容的参数名，对应chunk里的Multipart对象名，默认对象名为file
        fileParameterName: 'upfile',
        //失败后最多自动重试上传次数
        maxChunkRetries: 3,
        //是否开启服务器分片校验，对应GET类型同名的target URL
        testChunks: true,
        // query: {
        //   //携带参数
        //   id: this.parentId,
        //   price: this.money,
        //   columnType: this.columnType,
        // },
        query: (file) => {
          return {
            //携带参数
            id: this.parentId,
            price: this.money,
            columnType: this.columnType,
            author: this.$store.state.user_info.realName,
            fileTitle: file.column[0].value,
            wordType: file.column[1].value,
            source: file.column[2].value,
            publishTime: file.column[3].value,
          }
        },
        /* 
        服务器分片校验函数，判断秒传及断点续传,传入的参数是Uploader.Chunk实例以及请求响应信息
        reponse码是successStatuses码时，才会进入该方法
        reponse码如果返回的是permanentErrors 中的状态码，不会进入该方法，直接进入onFileError函数 ，并显示上传失败
        reponse码是其他状态码，不会进入该方法，正常走标准上传
        checkChunkUploadedByResponse函数直接return true的话，不再调用上传接口
        */
        checkChunkUploadedByResponse: function (chunk, response_msg) {
          // console.log(response_msg)
          let objMessage = JSON.parse(response_msg)
          if (objMessage.status == '415') {
            return true
          }
          if (objMessage.skipUpload) {
            return true
          }
          return (
            (objMessage.uploadedChunks || []).indexOf(chunk.offset + 1) >= 0
          )
        },
      },
      attrs: {
        accept: ACCEPT_CONFIG.getAll(this.fileType),
      },
      fileStatusText: {
        success: '上传成功',
        error: '上传失败',
        uploading: '上传中',
        paused: '暂停',
        waiting: '等待上传',
      },
      uploadBtnKey: '',
      uploaderKey: '',
      tableData: [],
      column: [
        {
          label: '文件名',
          prop: 'name',
          width: 220,
          type: 'text',
          edit: false,
          value: '',
        },
        {
          label: '文献类型',
          type: 'select',
          edit: false,
          value: '期刊[J]',
        },
        {
          label: '来源',
          type: 'text',
          edit: false,
          value: '写手智能科技数据库',
        },
        {
          label: '创作时间',
          type: 'date',
          edit: false,
          value: this.format(Date.now()),
        },
      ],
      docs: [
        {
          name: '期刊[J]',
        },
        {
          name: '专著[M]',
        },
        {
          name: '论文集[C]',
        },
        {
          name: '学位论文[D]',
        },
        {
          name: '专利[P]',
        },
        {
          name: '标准[S]',
        },
        {
          name: '报纸[N]',
        },
        {
          name: '技术报告[R]',
        },
      ],
      fileIndex: 0,
    }
  },
  watch: {
    parentId: {
      handler: function (val) {
        this.options.query.id = val
      },
    },

    money: {
      handler: function (val) {
        this.options.query.price = val
      },
    },

    fileType() {
      this.$set(this.attrs, 'accept', ACCEPT_CONFIG.getAll(this.fileType))
      if (this.$refs['uploadBtn']) {
        this.uploadBtnKey = new Date().getTime()
      }
    },
  },
  created() {
    //清空文件列表
    this.$bus.$on('clearFileList', () => {
      this.uploaderKey = new Date().getTime()
    })
  },
  methods: {
    selectChange(cur, ri, ci) {
      const col = this.tableData[ri].column[ci]
      const content = col.cell.querySelector('.content')
      content.innerText = cur
      col.edit = false
    },
    editCellBlur(ri, ci, e, blur) {
      const code = e.keyCode || e.which
      if (code == 13 || blur) {
        const val = this.tableData[ri].column[ci].value
        if (val) {
          const content =
            this.tableData[ri].column[ci].cell.querySelector('.content')
          if (this.tableData[ri].column[ci].label == '文件名') {
            const suffix = this.tableData[ri].suffix
            this.tableData[ri].column[ci].value = val + suffix
            content.innerText = val + suffix
          } else {
            content.innerText = val
          }
        }
        this.tableData[ri].column[ci].edit = false
      }
    },
    cellClick(r, c, l, e) {
      e.stopPropagation()
      this.tableData.forEach((t) => {
        if (t.paused && t.id === r.id) {
          t.column.forEach((col) => {
            if (col.label == c.label) {
              this.tableData.forEach((t1) => {
                t1.column.forEach((c1) => (c1.edit = false))
              })
              col.edit = true
              col.cell = l
              if (col.label == '文件名') {
                col.value = l.innerText.replace(/\.(doc|docx)$/, '')
              } else {
                col.value = l.innerText
              }
              setTimeout(() => {
                this.$refs['textinput'].forEach((ti) => {
                  if (ti.className.indexOf('show') !== -1) {
                    ti.focus()
                  }
                })
              }, 300)
            }
          })
        }
      })
    },
    onFileAdded(file) {
      let fileSizeLimit = 50 * 1024 * 1024
      // console.log("文件大小：" + file.size);
      // console.log("限制大小：" + fileSizeLimit);
      if (file.size > fileSizeLimit) {
        this.$message({
          showClose: true,
          message: '文件大小不能超过50M',
        })
        file.cancel()
        return
      }
      const column = JSON.parse(JSON.stringify(this.column))
      const suffix = file.name.slice(file.name.lastIndexOf('.'))
      column[0].value = file.name
      if (file.__ob__) {
        delete file.__ob__
      }
      file.column = column
      file.suffix = suffix
      this.tableData.push(file)
      this.$emit('fileAdded', this.tableData)
      this.fileIndex++
    },
    //移除一个选择的文件
    removeFile(file) {
      this.tableData.forEach((f, fi) => {
        if (f.id === file.id) {
          this.tableData.splice(fi, 1)
          this.fileIndex = this.tableData.length
        }
      })
      if (!this.tableData.length) {
        this.$bus.$emit('clearFileList')
      }
    },
    /*
            第一个参数 rootFile 就是成功上传的文件所属的根 Uploader.File 对象，它应该包含或者等于成功上传文件；
            第二个参数 file 就是当前成功的 Uploader.File 对象本身；
            第三个参数就是 message 就是服务端响应内容，永远都是字符串；
            第四个参数 chunk 就是 Uploader.Chunk 实例，它就是该文件的最后一个块实例，如果你想得到请求响应码的话，chunk.xhr.status就是
            */
    onFileSuccess(rootFile, file, response, chunk) {
      let objMessage = JSON.parse(response)
      if (objMessage.skipUpload) {
        this.$emit('onFileSuccess', file)
        //refProjectId为预留字段，可关联附件所属目标，例如所属档案，所属工程等
        file.refProjectId = '123456789'
        // file.id = this.parentId;
        // console.log(file)
        return false
      }
      file.columnType = this.columnType

      this.$store.commit('playLifeLoading', true)
      mergeFile(file)
        .then((responseData) => {
          this.$store.commit('playLifeLoading', false)
          this.$emit('onFileSuccess', file)
          if (responseData?.data.code === 415) {
            console.log('合并操作未成功，结果码：' + responseData.data.code)
          }
        })
        .catch(function (error) {
          console.log('合并后捕获的未知异常：' + error)
        })
    },
    onFileError(rootFile, file, response, chunk) {
      console.log('上传完成后异常信息：' + response)
      let objMessage = JSON.parse(response)
      //  this.error(`文件${file.name}上传失败，${objMessage.message}`);
      this.$message({
        type: 'error',
        message: `文件${file.name}上传失败，${objMessage.message}`,
        duration: 2000,
      })
    },

    /**
     * 计算md5，实现断点续传及秒传
     * @param file
     */
    computeMD5(file) {
      console.log(file)
      file.pause()
      // console.log(file)
      //单个文件的大小限制50M
      // let fileSizeLimit = 50 * 1024 * 1024;
      // // console.log("文件大小：" + file.size);
      // // console.log("限制大小：" + fileSizeLimit);
      // if (file.size > fileSizeLimit) {
      //   this.$message({
      //     showClose: true,
      //     message: "文件大小不能超过50M",
      //   });
      //   file.cancel();
      //   return;
      // }

      let fileReader = new FileReader()
      let time = new Date().getTime()
      let blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice
      let currentChunk = 0
      const chunkSize = 10 * 1024 * 1000
      let chunks = Math.ceil(file.size / chunkSize)
      let spark = new SparkMD5.ArrayBuffer()
      //由于计算整个文件的Md5太慢，因此采用只计算第1块文件的md5的方式
      let chunkNumberMD5 = 1

      loadNext()

      fileReader.onload = (e) => {
        spark.append(e.target.result)

        if (currentChunk < chunkNumberMD5) {
          loadNext()
        } else {
          let md5 = spark.end()
          file.uniqueIdentifier = md5
          file.resume()
          // console.log(
          //   `MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${
          //     file.size
          //   } 用时：${new Date().getTime() - time} ms`
          // );
        }
      }

      fileReader.onerror = function () {
        this.error(`文件${file.name}读取出错，请检查该文件`)
        file.cancel()
      }

      function loadNext() {
        let start = currentChunk * chunkSize
        let end = start + chunkSize >= file.size ? file.size : start + chunkSize

        fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end))
        currentChunk++
        // console.log("计算第" + currentChunk + "块");
      }
    },
    close() {
      this.uploader.cancel()
    },
    error(msg) {
      this.$notify({
        title: '错误',
        message: msg,
        type: 'error',
        duration: 2000,
      })
    },
    format(time) {
      const d = new Date(time)
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      let date = d.getDate()
      month = month < 10 ? '0' + month : month
      date = date < 10 ? '0' + date : date
      return year + '-' + month + '-' + date
    },
    clickHandler() {
      this.tableData.forEach((t) => {
        t.column.forEach((c) => {
          c.edit = false
        })
      })
    },
  },
  mounted() {
    document.addEventListener('click', this.clickHandler.bind(this))
  },
  destroyed() {
    this.$bus.$off('clearFileList')
    document.removeEventListener('click', this.clickHandler)
  },
}
</script>

<style lang="scss">
.el-select-dropdown {
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: none;
  }
}
</style>
<style lang="scss">
.uploader-ui {
  margin: 56px auto 0;
  font-size: 12px;
  padding-bottom: 20px;
  font-family: Microsoft YaHei;
}
.uploader-ui .uploader-btn {
  margin-right: 4px;
  font-size: 12px;
  border-radius: 3px;
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
}
.uploader-ui div.uploader-list {
  max-height: 300px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  .el-table td.el-table__cell {
    overflow: hidden;
    &:nth-child(2) {
      .cell {
        > div {
          @include ellipsis;
          min-width: 200px;
        }
      }
    }
    .cell {
      @include flex-start;
      @include ellipsis;
      @include noselect;
      min-height: 40px;
      .edit-cell,
      .select-cell,
      .date-cell {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        padding: 3px 5px;
        opacity: 0;
        visibility: hidden;
        border: 1px solid #ddd;
        border-radius: 3px;
        background-color: #fff;
        outline: none;
        box-sizing: border-box;
        overflow: hidden;
        transition: width 0.3s ease 0s, opacity 0.3s ease 0s,
          visibility 0.3s ease 0s;
        &.show {
          visibility: visible;
          opacity: 1;
          width: 100%;
          height: 100%;
        }
      }
      .select-cell {
        padding: 0;
        .el-input {
          height: 100%;
          input {
            @include ellipsis;
            width: 80%;
            height: 100%;
            padding: 3px 5px;
            border: none;
          }
        }
      }
      .date-cell {
        padding: 0;
        .el-input__prefix {
          display: none;
        }
        input {
          @include ellipsis;
          height: 100%;
          padding: 3px 5px;
          border: none;
        }
      }
      .operate {
        @include flex-start;
        > span {
          &:first-child ~ span {
            margin-left: 10px;
          }
        }
        .error {
          color: #ce2e2e;
        }
        .success {
          color: rgb(8, 160, 8);
        }
        .delete {
          cursor: pointer;
        }
      }
    }
  }
}
.upload-tip {
  text-align: center;
  line-height: 1;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #4587ff;
}
.uploader-drop > .text {
  position: absolute;
  top: 50%;
  left: 150px;
  transform: translateY(-50%);
  width: 565px;
}
.uploader-drop > .text > span {
  color: red;
}
</style>
