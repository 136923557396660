<template>
  <div class="signature">
    <div class="signatureBox">
      <div class="canvasBox" ref="canvasHW">
        <canvas
          ref="canvasF"
          :width="width"
          :height="height"
          @mousedown="mouseDown"
          @mousemove="mouseMove"
          @mouseup="mouseUp"
        ></canvas>
        <div class="btnBox">
          <div @click="overwrite">重写</div>
          <div @click="commit">提交签名</div>
        </div>
      </div>
    </div>
    <!-- <img class="imgCanvas" :src="imgUrl"> -->
  </div>
</template>

<script>
export default {
  name: "Signature",
  props: {
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 220,
    },
  },
  data() {
    return {
      stageInfo: "",
      imgUrl: "",
      client: {},
      points: [],
      canvasTxt: null,
      startX: 0,
      startY: 0,
      moveY: 0,
      moveX: 0,
      isDown: false,
    };
  },
  methods: {
    mouseDown(ev) {
      ev = ev || event;
      ev.preventDefault();
      let obj = {
        x: ev.offsetX,
        y: ev.offsetY,
      };
      this.startX = obj.x;
      this.startY = obj.y;
      this.canvasTxt.beginPath();
      this.canvasTxt.moveTo(this.startX, this.startY);
      this.canvasTxt.lineTo(obj.x, obj.y);
      this.canvasTxt.stroke();
      this.canvasTxt.closePath();
      this.points.push(obj);
      this.isDown = true;
    },
    mouseMove(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (this.isDown) {
        let obj = {
          x: ev.offsetX,
          y: ev.offsetY,
        };
        this.moveY = obj.y;
        this.moveX = obj.x;
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.startY = obj.y;
        this.startX = obj.x;
        this.points.push(obj);
      }
    },
    mouseUp(ev) {
      ev = ev || event;
      ev.preventDefault();
      let obj = {
        x: ev.offsetX,
        y: ev.offsetY,
      };
      this.canvasTxt.beginPath();
      this.canvasTxt.moveTo(this.startX, this.startY);
      this.canvasTxt.lineTo(obj.x, obj.y);
      this.canvasTxt.stroke();
      this.canvasTxt.closePath();
      this.points.push(obj);
      this.points.push({ x: -1, y: -1 });
      this.isDown = false;
    },
    //重写
    overwrite() {
      this.canvasTxt.clearRect(
        0,
        0,
        this.$refs.canvasF.width,
        this.$refs.canvasF.height
      );
      this.points = [];
    },
    //提交签名
    commit() {
      this.imgUrl = this.$refs.canvasF.toDataURL();
      this.$emit("handleSubmit", this.imgUrl);
    },
  },
  mounted() {
    let canvas = this.$refs.canvasF;
    this.canvasTxt = canvas.getContext("2d");
    this.stageInfo = canvas.getBoundingClientRect();
  },
};
</script>

<style lang="scss" scoped>
.signature {
  .signatureBox {
    @include flex-start;
    height: calc(100% - 50px);
    box-sizing: border-box;
    overflow: hidden;
    background: #fff;
    z-index: 100;
    display: flex;
    flex-direction: column;
  }
  .canvasBox {
    box-sizing: border-box;
    flex: 1;
  }
  canvas {
    border: 1px solid #4588ff;
    border-radius: 5px;
  }
  .btnBox {
    @include flex-between(space-around);
    padding: 10px;
    text-align: center;
    > div {
      @include noselect;
      padding: 0 5px;
      height: 40px;
      width: 80px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      color: #fff;
      background-color: #4588ff;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
</style>
